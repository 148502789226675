import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { UsersComponent } from "./features/users/users.component";
import { LoginComponent } from "./features/login/login.component";

const routes: Routes = [
  {
    path: "dashboard",
    component: UsersComponent
  },
  {
    path: "",
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
