import { Injectable } from "@angular/core";
// import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DoorsService {
  constructor(private httpClient: HttpClient) {}

  getUserAptList(): Observable<any> {
    const api =
      "https://nrmapi.azurewebsites.net/api/getuserdoor?aptid=D4C888BE-C32A-4F5D-A803-A8C368317E7B";
    return this.httpClient.get(api);
  }

  updateUser(userData): Observable<any> {
    console.log("in put req - update: ", userData);
    const api = "https://nrmapi.azurewebsites.net/api/UpdateDoor";
    return this.httpClient.post(api, userData);
  }
}
