import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import {
  UsersComponent,
  UserDialogBox,
  UserDeleteDialogBox
} from "./features/users/users.component";
import { LoginComponent } from "./features/login/login.component";
import { UsersService } from "./features/users/users.service";
import { MaterialModule } from "./material.module";
import { MatNativeDateModule } from "@angular/material/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  DoorsComponent,
  DoorDialogBox
} from "./features/doors/doors.component";
import { DoorsService } from "./features/doors/doors.service";
import { SupportService } from "./features/support/support.service";
import {
  SupportComponent,
  SupportDialogBox
} from "./features/support/support.component";

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    LoginComponent,
    DoorsComponent,
    UserDialogBox,
    UserDeleteDialogBox,
    DoorDialogBox,
    SupportComponent,
    SupportDialogBox
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    MatNativeDateModule,
    BrowserAnimationsModule
  ],
  providers: [UsersService, DoorsService, SupportService],
  bootstrap: [AppComponent],
  entryComponents: [
    UsersComponent,
    DoorsComponent,
    SupportComponent,
    UserDialogBox,
    UserDeleteDialogBox,
    DoorDialogBox,
    SupportDialogBox
  ]
})
export class AppModule {}
