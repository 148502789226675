import { Injectable } from "@angular/core";
// import { Observable } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class UsersService {
  http: any;
  constructor(private httpClient: HttpClient) {}

  getUserList(): Observable<any> {
    const api = "https://jsonplaceholder.typicode.com/users";
    return this.httpClient.get(api);
  }
  getDoorList(): Observable<any> {
    const api = "https://nrmapi.azurewebsites.net/api/GetDoors";
    return this.httpClient.get(api);
  }

  // Get All Users Endpoint
  getUserAptList(): Observable<any> {
    const api = "https://nrmapi.azurewebsites.net/api/GetAllUsers";
    return this.httpClient.get(api);
  }

  postUser(userData): Observable<any> {
    console.log("in post req - create: ", userData);
    const api = "https://nrmapi.azurewebsites.net/api/CreateUser";
    return this.httpClient.post(api, userData);
  }

  updateUser(userData): Observable<any> {
    console.log("in put req - update: ", userData);
    const api = "https://nrmapi.azurewebsites.net/api/UpdateUser";
    return this.httpClient.post(api, userData);
  }

  deleteUser(id): Observable<any> {
    console.log("in delete req - delete: ", id);
    // const api = `https://nrmapi.azurewebsites.net/api/DeleteUser?id=90459605-533B-4486-A22C-3373C4C006C6`;
    const api = `https://nrmapi.azurewebsites.net/api/DeleteUser?id=${id}`;
    return this.httpClient.post(api, {});
  }
  alldevicestatus(formData): Observable<any> {
    // console.log("All Device Status in post req - create: ", deviceData);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };
    // const formData = new FormData();
    formData.append(
      "username",
      "ZjlkOHVpZAD1DCBBBD7D7B2FDC74D987628EF346A10F426FEF6B8498CFB3F3ABF403BEF31F52911371C505B80E"
    );
    const api = "https://shelly-6-eu.shelly.cloud/device/all_status";
    return this.httpClient.post(api, formData, httpOptions);
  }
}
